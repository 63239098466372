<template>
  <v-dialog v-model="internalOpen" max-width="1200">
    <div
      style="background-color: #ffffff; padding: 0 1em 2em; position: relative"
    >
      <div
        class="py-4"
        style="
          width: 100%;
          background-color: inherit;
          position: sticky;
          top: 0;
          z-index: 10;
        "
      >
        <v-btn
          style="
            min-width: unset !important;
            min-height: unset !important;
            width: 32px !important;
            height: 32px !important;
          "
          class="px-4 py-4"
          @click.stop="internalOpen = false"
          text
          rounded
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </div>

      <div>
        <v-card-title style="gap: 8px" class="align-center flex-wrap">
          <v-icon>mdi-history</v-icon>
          <span class="text-uppercase text-subtitle-2 font-weight-bold"
            >Bitacora de solicitud</span
          >
        </v-card-title>
        <v-card>
          <app-time-line
            :items="timeline"
            :showActions="false"
            :loading="bitacoras.isLoading"
            style="width: 100%"
          >
            <template v-slot:title>
              <v-card-title class="d-block text-center font-weight-bold mb-2">
                <h2 style="font-size: 24px" class="text-uppercase">
                  Historial
                </h2>
              </v-card-title>
            </template>
            <template v-slot:suffix="{ item }">
              <v-btn
                v-if="item.adjunto"
                small
                class="ml-1"
                color="primary"
                @click.stop="loadAttachment(item.adjunto)"
              >
                <v-icon class="mr-1" color="#FFFFFF !important"
                  >mdi mdi-attachment</v-icon
                >
                Ver
              </v-btn>
            </template>
          </app-time-line>
        </v-card>
        <PdfModal
          :isOpen="isPdfModalOpen"
          :source-loadable="pdfLoadable"
          @on-visibility-change="handlePdfModalVisibility"
          :filename="filename"
        />
      </div>
    </div>
  </v-dialog>
</template>

<script>
import {
  createLoadable,
  setLoadableResponse,
  toggleLoadable,
} from "@/utils/loadable";
import { isNil } from "lodash";
import { formatDate } from "@/utils/datetime";
import PdfModal from "@/components/PdfModal.vue";

export default {
  name: "CmModalBitacoraSolicitud",
  props: {
    isOpen: { type: Boolean },
    idSolicitud: { type: Number },
  },
  components: { PdfModal },

  data() {
    return {
      isPdfModalOpen: false,
      filename: "",
      activePath: "",
      bitacoras: createLoadable([]),
      internalOpen: false,
      pdfLoadable: createLoadable(null),
    };
  },
  computed: {
    timeline() {
      const bitacoras = this.bitacoras.data ?? [];

      return bitacoras.map(
        ({ descripcion, fecha, comentario, id, sec_bit, adjunto }) => ({
          id,
          descripcion,
          fecha: formatDate(fecha),
          comentario,
          color: sec_bit?.retroceso_en_flujo ? "red" : "success",
          adjunto,
        })
      );
    },
  },
  methods: {
    async obtenerBitacoraSolicitud() {
      toggleLoadable(this.bitacoras);
      const { data } = await this.services.CmHerramienta.bitacoraSolicitud(
        this.idSolicitud
      );
      setLoadableResponse(this.bitacoras, data);
    },
    async loadAttachment(path) {
      this.isPdfModalOpen = true;
      if (this.activePath === path) return;

      this.filename = getFilenameFromPath(path);
      this.activePath = path;
      toggleLoadable(this.pdfLoadable);
      const { data } =
        await this.services.cmAprobacionSolicitudCompra.loadAttachment({
          nombre: path,
        });

      setLoadableResponse(this.pdfLoadable, data, { isFile: true });
    },
    handlePdfModalVisibility(visible) {
      this.isPdfModalOpen = visible;
    },
  },
  watch: {
    isOpen(value) {
      if (isNil(value)) return;

      this.internalOpen = value;
      this.obtenerBitacoraSolicitud();
    },
    internalOpen(value) {
      this.$emit("on-visibility-change", value);
    },
  },
};
</script>
