<template>
  <v-card class="elevation-2" v-if="productoSolicitud">
    <v-card-title class="d-flex align-center">
      <v-icon class="mr-2">mdi-cart</v-icon>
      <span class="font-weight-bold mr-2">Productos de la solicitud</span>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="encabezado"
        :items="productoSolicitud"
        class="mb-5"
      >
        <template v-slot:item.producto="{ item }">
          <span>{{ item.cmopc.CMProducto.nombre }}</span>
        </template>
        <template v-slot:item.sku="{ item }">
          <span>{{ item.cmopc.CMProducto.cmsku.sku }}</span>
        </template>
        <template v-slot:item.estado="{ item }">
          <span>{{ item.cmbps[0].sec_bit.es_sec.nombre }}</span>
        </template>
        <template v-slot:item.precio_unitario="{ item }">
          <span>${{ item.precio_de_oferta }}</span>
        </template>
        <template v-slot:item.cantidad="{ item }">
          <span>{{ esConvenio(item) }}</span>
        </template>
        <template v-slot:item.total="{ item }">
          <span>${{ item.subtotal }}</span>
        </template>
        <template v-slot:item.acciones="{ item }">
          <v-btn color="secondary" icon @click.stop="abrirHistorial(item)">
            <v-icon>mdi-eye</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <CmModalBitacoraProducto
      :isOpen="abrirModal"
      :id-producto-solicitud="idProductoSolicitud"
      @on-visibility-change="cambioEstadoModal"
    />
  </v-card>
</template>
<script>
import { createLoadable } from "@/utils/loadable";
import CmModalBitacoraProducto from "./CmModalBitacoraProducto.vue";

export default {
  name: "ProductoSolicitudCompra",
  props: {
    productoSolicitud: { type: Array },
  },
  components: { CmModalBitacoraProducto },
  data: () => ({
    encabezado: [
      {
        text: "Producto",
        align: "center",
        value: "producto",
      },
      {
        text: "SKU",
        align: "center",
        value: "sku",
      },
      {
        text: "Estado",
        align: "center",
        value: "estado",
      },
      {
        text: "Precio unitario ($)",
        align: "center",
        value: "precio_unitario",
      },
      {
        text: "Cantidad",
        align: "center",
        value: "cantidad",
      },
      {
        text: "Total",
        align: "center",
        value: "total",
      },
      {
        text: "Acciones",
        align: "center",
        value: "acciones",
      },
    ],
    idProductoSolicitud: null,
    abrirModal: false,
  }),
  emits: ["close"],
  computed: {},
  methods: {
    cambioEstadoModal(visible) {
      this.abrirModal = visible;
    },
    esConvenio(item) {
      const subGrupo = item.cmopc.CMProducto.cm_sg;
      const esCMPublicaciones =
        subGrupo.cantidad &&
        (subGrupo.altura ||
          subGrupo.altura_decimal ||
          subGrupo.base ||
          subGrupo.base_decimal);

      return esCMPublicaciones ? item.altura * item.base : item.cantidad;
    },
    abrirHistorial(item) {
      this.abrirModal = true;
      this.idProductoSolicitud = item.id;
    },
  },
};
</script>
