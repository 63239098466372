<template>
  <v-row v-if="solicitudCompra">
    <v-col cols="8">
      <v-card class="elevation-2">
        <v-card-title class="d-flex align-center">
          <v-icon class="mr-2">mdi-information-slab-circle-outline</v-icon>
          <span class="font-weight-bold mr-2"
            >Información de la solicitud de compra</span
          >
        </v-card-title>
        <v-card-text>
          <div class="d-flex flex-column flex-md-row" style="gap: 4px">
            <dl class="flex-grow-1 flex-shrink-1" style="flex-basis: 50%">
              <dt class="font-weight-bold text-body-2 mt-3">
                Número de solicitud:
              </dt>
              <dd class="text-body-2">{{ solicitudCompra.id }}</dd>
              <dt class="font-weight-bold text-body-2 mt-3">
                Tipo solicitud / Convenio
              </dt>
              <dd class="text-body-2">
                {{ solicitudCompra.cmtsc.nombre }} -
                {{
                  solicitudCompra.cmpsc[0].cmopc.CMProducto.cm_sg.cmg.CmFamilium
                    .nombre
                }}
              </dd>
              <dt class="font-weight-bold text-body-2 mt-3">Proceso compra:</dt>
              <dd class="text-body-2">
                {{ solicitudCompra.ProcesoCompra.codigo_proceso }}
              </dd>
              <dt class="font-weight-bold text-body-2 mt-3">
                Institución / Unidad:
              </dt>
              <dd class="text-body-2">
                {{
                  ` ${solicitudCompra.Institucion.nombre} ${solicitudCompra.Unidad.nombre}`
                }}
              </dd>
            </dl>

            <!-- Segundo apartado -->

            <dl class="flex-grow-1 flex-shrink-1" style="flex-basis: 50%">
              <dt class="font-weight-bold text-body-2 mt-3">
                Fecha creación carrito:
              </dt>
              <dd class="text-body-2">
                {{
                  moment(solicitudCompra.created_at).format("DD/MM/YYYY h:mm a")
                }}
              </dd>

              <dt class="font-weight-bold text-body-2 mt-3">
                Fecha de envio a el proveedor:
              </dt>
              <dd class="text-body-2">
                {{
                  solicitudCompra.fecha_autorizacion
                    ? moment(solicitudCompra.fecha_autorizacion).format(
                        "DD/MM/YYYY h:mm a"
                      )
                    : "-"
                }}
              </dd>
              <dt class="font-weight-bold text-body-2 mt-3">
                Fecha expiración carrito:
              </dt>
              <dd
                class="text-body-2"
                :style="{
                  color: expiracionAutorizacion ? 'red' : 'green',
                }"
              >
                {{
                  moment(solicitudCompra.expiracion_carrito).format(
                    "DD/MM/YYYY h:mm a"
                  )
                }}
              </dd>
              <dt class="font-weight-bold text-body-2 mt-3">
                Fecha de expiración de aprobación:
              </dt>
              <dd
                class="text-body-2"
                :style="{
                  color: expiracionAutorizacion ? 'red' : 'green',
                }"
              >
                {{
                  moment(solicitudCompra.expiracion_autorizacion).format(
                    "DD/MM/YYYY h:mm a"
                  )
                }}
              </dd>
            </dl>

            <!-- Tercer apartado -->
            <dl class="flex-grow-1 flex-shrink-1" style="flex-basis: 50%">
              <dt class="font-weight-bold text-body-2">
                Enviada a el proveedor:
              </dt>
              <dd class="text-body-2">
                <v-chip
                  label
                  small
                  class="text-uppercase font-weight-bold"
                  :color="solicitudCompra.autorizada ? 'green' : 'red'"
                  text-color="white"
                >
                  {{ solicitudCompra.autorizada ? "Si" : "No" }}
                </v-chip>
              </dd>

              <dt class="font-weight-bold text-body-2 mt-3">Descartada:</dt>
              <dd class="text-body-2">
                <v-chip
                  label
                  small
                  class="text-uppercase font-weight-bold"
                  :color="solicitudCompra.descartada ? 'green' : 'red'"
                  text-color="white"
                >
                  {{ solicitudCompra.descartada ? "Si" : "No" }}
                </v-chip>
              </dd>

              <dt class="font-weight-bold text-body-2 mt-3">Expirada:</dt>
              <dd class="text-body-2">
                <v-chip
                  label
                  small
                  class="text-uppercase font-weight-bold"
                  :color="solicitudCompra.expirada ? 'green' : 'red'"
                  text-color="white"
                >
                  {{ solicitudCompra.expirada ? "Si" : "No" }}
                </v-chip>
              </dd>

              <dt class="font-weight-bold text-body-2 mt-3">Vencida:</dt>
              <dd class="text-body-2">
                <v-chip
                  label
                  small
                  class="text-uppercase font-weight-bold"
                  :color="solicitudCompra.vencida ? 'green' : 'red'"
                  text-color="white"
                >
                  {{ solicitudCompra.vencida ? "Si" : "No" }}
                </v-chip>
              </dd>
            </dl>
          </div>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="4">
      <v-card class="elevation-2">
        <v-card-text>
          <dl class="flex-grow-1 flex-shrink-1" style="flex-basis: 50%">
            <dt class="font-weight-bold text-body-2 mt-3">
              Administrador de contrato:
            </dt>
            <dd class="text-body-2">{{ solicitudCompra.us_c?.email }}</dd>
            <dt class="font-weight-bold text-body-2 mt-3">Creado por:</dt>
            <dd class="text-body-2">
              {{ solicitudCompra.u_elaborado?.email }}
            </dd>
          </dl>
          <dt class="font-weight-bold text-body-2 mt-3">
            Ver historial de la solicitud
          </dt>
          <dl>
            <v-btn
              color="secondary"
              icon
              @click.stop="verBitacoraSolicitud(solicitudCompra)"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </dl>
        </v-card-text>
      </v-card>
    </v-col>
    <CmModalBitacoraSolicitud
      :isOpen="abrirModal"
      :id-solicitud="idSolicitud"
      @on-visibility-change="cambioEstadoModal"
    />
  </v-row>
</template>
<script>
import moment from "moment";
import CmModalBitacoraSolicitud from "./CmModalBitacoraSolicitud.vue";

export default {
  name: "InformacionSolicitudCompra",
  props: {
    solicitudCompra: { type: Object, required: false },
  },
  components: { CmModalBitacoraSolicitud },
  data: () => ({
    idSolicitud: null,
    abrirModal: false,
  }),
  emits: ["close"],
  computed: {
    expiracionCarrito() {
      return moment(this.solicitudCompra.expiracion_carrito).isBefore(moment());
    },
    expiracionAutorizacion() {
      return moment(this.solicitudCompra.expiracion_autorizacion).isBefore(
        moment()
      );
    },
  },
  methods: {
    cambioEstadoModal(visible) {
      this.abrirModal = visible;
    },
    verBitacoraSolicitud(item) {
      this.abrirModal = true;
      this.idSolicitud = item.id;
    },
  },
};
</script>
