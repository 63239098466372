<template>
  <div>
    <v-card class="mb-4">
      <v-card-title style="gap: 8px; line-height: 32px">
        <NavButton />
        <h3 class="text-h5 font-weight-bold text-uppercase">
          Catálogo electrónico
        </h3>
      </v-card-title>
      <v-divider />
      <v-row class="px-4 py-4">
        <v-col cols="12">
          <v-autocomplete
            :items="tipoSolicitud"
            item-text="nombre"
            item-value="id"
            label="Tipo de solicitud"
            outlined
            clearable
            hide-details
            v-model="filtros.tipo_solicitud"
            @change="limpiarFiltros()"
          />
        </v-col>
        <v-col cols="12" md="8" lg="8">
          <v-text-field
            v-model="filtros.codigo"
            outlined
            hide-details
            clearable
            placeholder="Código orden de compra"
            label="Código / Orden de compra"
            class="mb-0"
            :disabled="!filtros.tipo_solicitud"
          />
        </v-col>

        <v-col cols="12" md="4" lg="4">
          <v-btn
            x-large
            outlined
            color="primary"
            @click.stop="limpiarFiltros"
            class="mr-2"
          >
            Limpiar
          </v-btn>

          <v-btn x-large color="primary" @click.stop="buscarOrden">
            Buscar
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

    <v-row justify="center" class="mt-4">
      <SpinnerComponent
        :spinning="informacion.isLoading"
        text="Cargando solicitud..."
      />

      <EmptyComponent
        :empty="sinInformacion"
        class="mt-4"
        colored
        style="min-width: 100%"
      >
        <template v-slot:empty-content>
          <v-icon style="font-size: 50px">mdi-card-bulleted-off-outline</v-icon>
          <p class="my-4 font-weight-bold text-h6">
            No se encontró ninguna solicitud u orden de compra.
          </p>
        </template>
      </EmptyComponent>
      <v-col cols="12">
        <template v-if="informacion.isLoading">
          <v-skeleton-loader
            type="card-heading, card"
            height="150px"
            class="mb-4"
          />
        </template>
        <template v-else>
          <InformacionSolicitudCompra :solicitud-compra="solicitudCompra" />
        </template>
      </v-col>

      <v-col cols="12">
        <template v-if="informacion.isLoading">
          <v-skeleton-loader
            type="card-heading, card"
            height="150px"
            class="mb-4"
            loadomg
            boilerplate
          />
        </template>
        <template v-else>
          <ProductoSolicitudCompra :producto-solicitud="productoSolicitud" />
        </template>
      </v-col>

      <v-col cols="12">
        <template v-if="informacion.isLoading">
          <v-skeleton-loader
            type="card-heading, card"
            height="150px"
            class="mb-4"
            loadomg
            boilerplate
          />
        </template>
        <template v-else>
          <ProveedorOrdenCompra
            v-if="tieneOrdenes"
            :proveedor-orden="proveedorOrden"
          />
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import NavButton from "@/components/utils/NavButton.vue";
import {
  createLoadable,
  setLoadableResponse,
  toggleLoadable,
} from "@/utils/loadable";
import InformacionSolicitudCompra from "./components/InformacionSolicitudCompra.vue";
import ProductoSolicitudCompra from "./components/ProductoSolicitudCompra.vue";
import ProveedorOrdenCompra from "./components/ProveedorOrdenCompra.vue";
import SpinnerComponent from "@/components/utils/SpinnerComponent.vue";
import EmptyComponent from "@/components/utils/EmptyComponent.vue";

export default {
  name: "CMHerramienta",
  components: {
    NavButton,
    InformacionSolicitudCompra,
    ProductoSolicitudCompra,
    ProveedorOrdenCompra,
    SpinnerComponent,
    EmptyComponent,
  },
  data: () => ({
    tipoSolicitud: [
      { id: 1, nombre: "Solicitud de compra" },
      { id: 2, nombre: "Orden de compra" },
    ],
    paginacion: {
      per_page: 10,
      page: 1,
    },

    filtros: {
      codigo: null,
      tipo_solicitud: null,
    },
    informacion: createLoadable(null),
    ordenCompraProveedor: createLoadable([]),
  }),
  computed: {
    solicitudCompra() {
      return +this.filtros.tipo_solicitud === 1
        ? this.informacion.data
        : this.informacion.data?.CMSolicitudCompra;
    },
    productoSolicitud() {
      return +this.filtros.tipo_solicitud === 1
        ? this.informacion.data?.cmpsc
        : this.informacion.data?.CMSolicitudCompra?.cmpsc;
    },
    proveedorOrden() {
      return this.ordenCompraProveedor.data;
    },
    tieneOrdenes() {
      return this.ordenCompraProveedor.data.length > 0;
    },
    cargandoInformacion() {
      return this.informacion.isLoading;
    },
    sinInformacion() {
      return !this.informacion.isLoading && !!!this.informacion.data;
    },
  },
  methods: {
    async cargarInformacionCatalogo() {
      toggleLoadable(this.informacion);
      const { data, headers } =
        await this.services.CmHerramienta.informacionCatalogo({
          ...this.filtros,
        });

      setLoadableResponse(this.informacion, data, headers);
    },

    async cargarOrdenesCompraPorProveedor() {
      toggleLoadable(this.ordenCompraProveedor);
      const { data, headers } =
        await this.services.CmHerramienta.ordenesCompraPorProveedor({
          ...this.filtros,
        });

      setLoadableResponse(this.ordenCompraProveedor, data, headers);
    },

    limpiarFiltros() {
      this.informacion = createLoadable(null);
      this.ordenCompraProveedor = createLoadable([]);
    },
    buscarOrden() {
      this.limpiarFiltros();
      this.cargarInformacionCatalogo();
      this.cargarOrdenesCompraPorProveedor();
    },
  },
};
</script>
