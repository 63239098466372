<template>
  <v-card class="elevation-2 mb-3" v-if="proveedorOrden">
    <v-card-title class="d-flex align-center">
      <v-icon class="mr-2">mdi-list-box-outline</v-icon>
      <span class="font-weight-bold mr-2">Órdenes de compra por proveedor</span>
    </v-card-title>
    <v-card-text>
      <v-expansion-panels accordion multiple>
        <v-expansion-panel
          v-for="(proveedor, index) in proveedorOrden"
          :key="index"
        >
          <v-expansion-panel-header>
            <b class="text-h6 font-weight-bold" style="line-height: 1.5rem">
              {{ proveedor.nombre_comercial }}
            </b>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list dense>
              <v-list-item
                v-for="(orden, i) in proveedor.ordenes"
                :key="i"
                class="d-flex align-center px-2 py-2"
              >
                <span class="text-h6 font-weight-bold text-uppercase">
                  Código: {{ orden.codigo }}
                </span>
                <v-btn
                  @click="descargarOrdenCompra(orden)"
                  :disabled="!orden.ruta_archivo"
                  color="primary"
                  class="ml-auto"
                >
                  Descargar
                </v-btn>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ProveedorOrdenCompra",
  props: {
    proveedorOrden: {
      type: Array,
    },
  },
  methods: {
    async descargarOrdenCompra(proveedor) {
      const response =
        await this.services.PurchaseRequest.obtenerOrdenCompraCatalogo({
          codigo_orden: proveedor.codigo,
        });
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const file = new File([blob], "orden-compra", {
        type: response.headers["content-type"],
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(file);
      link.download = file.name;
      link.click();
    },
  },
};
</script>
